import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import ExperienceHeader from "../components/experience-header";
import AnimatedText from "../components/animated-text";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Skills from "../components/skills";
export const _frontmatter = {
  "title": "About"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <SEO title='About' description='A section about me and my CV.' mdxType="SEO" />
      <h1><AnimatedText mdxType="AnimatedText">{`About.`}</AnimatedText></h1>
      <p>{`I’m a bit of a jack of all trade. I’ve been mixing between graphical
design and programming since I was a teenager. Even though I love UX/UI
design, I’m most passionate about programming. Both on the frontend and
backend. I prefer functional programming, and Javascript is my language
of choice, but I love the opportunity to work with other functional
languages.`}</p>
      <p>{`Most of my projects are available on `}<a parentName="p" {...{
          "href": "https://github.com/antonstrand"
        }}>{`Github`}</a>{`. If you want the good stuff directly, you can visit `}<a parentName="p" {...{
          "href": "https://tasktracker.se/project/5b14049c1363ca6d2dec1f62"
        }}>{`tasktracker.se`}</a>{` or run `}<inlineCode parentName="p">{`npx play-snake 20`}</inlineCode>{` to `}<a parentName="p" {...{
          "href": "https://www.npmjs.com/package/play-snake"
        }}>{`play snake`}</a>{` in your terminal. `}</p>
      <p>{`When possible, my girlfriend, Victoria, our dog, Algot, and I often go
on trips with our self-built `}<a parentName="p" {...{
          "href": "https://www.instagram.com/jerrythevan/"
        }}>{`camper van`}</a>{`.`}</p>
      <hr></hr>
      <h2>{`Skills`}</h2>
      <Skills mdxType="Skills">
        <ul>
          <li parentName="ul">{`Javascript`}</li>
          <li parentName="ul">{`Git`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://nodejs.org/en/"
            }}>{`NodeJS`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://reactjs.org/"
            }}>{`React`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://redux.js.org/"
            }}>{`Redux`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://expressjs.com/"
            }}>{`Express`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.gatsbyjs.org/"
            }}>{`Gatsby`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://graphql.org/"
            }}>{`GraphQL`}</a></li>
          <li parentName="ul">{`API Design`}</li>
          <li parentName="ul">{`ReST API`}</li>
          <li parentName="ul">{`SQL`}</li>
          <li parentName="ul">{`NoSQL`}</li>
          <li parentName="ul">{`HTML5`}</li>
          <li parentName="ul">{`CSS3 `}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.npmjs.com/package/paperplane/"
            }}>{`Paperplane`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://ramdajs.com/"
            }}>{`Ramda`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://crocks.dev/"
            }}>{`Crocks (Algebraic Data Types)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.sketch.com/"
            }}>{`Sketch`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.figma.com/"
            }}>{`Figma`}</a></li>
          <li parentName="ul">{`Photoshop`}</li>
        </ul>
      </Skills>
      <hr></hr>
      <h2>{`Work experience`}</h2>
      <ExperienceHeader title="Project Assistant" period="Linnaeus University • June 2019 - present" mdxType="ExperienceHeader">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "216px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": ["noopener"]
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADVklEQVQ4y21VaYhNYRh+7hxL/ESUyM5gMoQwkl3JkiwZ1KSIMiEjDMpknWxl3yJ+oCxFU5TlhyUlP5AfJObOHXcYZsxkmUEz957H+37vOfdey6mvc873vef5nud53/c7oO/TXXr3m5m6fj6gX1dEVufRj3eV0YX8MELmVpI/7qTj9JsMDKTBEjb56wn96nFkBeiXy3IUTJbbSLyV8Qa2puA/7wdAyRQoQjB99b+dcMH6sV8RYTLqMRHNEpYR8qOMGnmu8dyaHw2Av+z5AxShTAcmQS4w7pHv5P5BRgN4ZBu4uxh8cBm8cRZuLSGbMWYq/C+lKfkIZTpmClaVxbrnyhA8WAJeOwneuwTu2wxGIjYYl/FJ4yMOVInwx+1Asl7VY80vYfb9Jdi7OzgoG9yzCbx6HBw/CuzfG9wr7wWzwYmjwTvnJf6jMXWq3ucGkiWbzgvxhZXy+hocPAAcngs+vgYe3Q72kQ2GDQIrH4GnSkEALFktIHVgs0qOZRnLxjIB1NJQdjHPyXQS6sHoQ7CFB2b3MtDDWw1o3XLw3H7wxS21J7ApFrCsLRBAqTOXDNklUW7elJ0GCwvAnL4ieyN45Ri4az04YxJ4ejfYtg04ZKAlzA/VKZmqngIoReskxyIGKDIuHjK/nt0E504FNxaCx3fKfQW4SZ7nyNyiWTK3wyS7bDt1LRSwS8rDZIWVypQxYId24NJ8kzkhT5Ixx55zB9hGasGyhWCTFLt6b4CeAErFh5KdH9XgysXgyCEmVT88fwAsWmpWlJ0BW7U0cAf2PigfBYx306Ss+jcpwrJZgjt3Aju2B7esNpl9e1g5lW4At6/NkBsmpSZfALXRAw9DL5z0uLFas8T8mj8dnDkZnDfNqoCfM73zrGwargSFrY0etZ3SQSafX60uF8zUOrO203VVYHFZxq6qnxR2UwCop0ZFSN+YOj+FZe1TsHUrKxll1vQ2LBWNjzjvHWDj9fC0Cc5APTVc1RtTV0K14N0LloChObZBCOaAtEM0rr44OBwS4fGVDEBLzQsZiXIJrvV44WDE9XH+DLDxlbB/p8nzUnFpMP/vAzYA1VNDGt1PAafND+fMs+yUzMzv8d9fgD5Lo/vSm9pO2gEuk1Jnfs0Cl01NQCgzE+M32GtqShX2Xm8AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Linnaeus University logo",
                "title": "Linnaeus University logo",
                "src": "/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png",
                "srcSet": ["/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png 216w"],
                "sizes": "(max-width: 216px) 100vw, 216px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </ExperienceHeader>
      <ul>
        <li parentName="ul">{`In charge of the university’s instance of Gitlab`}</li>
        <li parentName="ul">{`Create a CLI tool to generate course content automatically`}</li>
        <li parentName="ul">{`Help students with questions and teaching programming concepts`}</li>
        <li parentName="ul">{`Assess assignments, both code reviews, and oral examinations`}</li>
        <li parentName="ul">{`Create assignment workflows including CI pipelines`}</li>
        <li parentName="ul">{`Write instructions for getting started and using Git and Gitlab`}</li>
        <li parentName="ul">{`Create a course page template using `}<a parentName="li" {...{
            "href": "https://www.gatsbyjs.org/"
          }}>{`Gatsby`}</a></li>
      </ul>
      <ExperienceHeader title="Teaching Assistant" period="Linnaeus University • Sep 2018 - June 2019" mdxType="ExperienceHeader">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "216px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": ["noopener"]
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADVklEQVQ4y21VaYhNYRh+7hxL/ESUyM5gMoQwkl3JkiwZ1KSIMiEjDMpknWxl3yJ+oCxFU5TlhyUlP5AfJObOHXcYZsxkmUEz957H+37vOfdey6mvc873vef5nud53/c7oO/TXXr3m5m6fj6gX1dEVufRj3eV0YX8MELmVpI/7qTj9JsMDKTBEjb56wn96nFkBeiXy3IUTJbbSLyV8Qa2puA/7wdAyRQoQjB99b+dcMH6sV8RYTLqMRHNEpYR8qOMGnmu8dyaHw2Av+z5AxShTAcmQS4w7pHv5P5BRgN4ZBu4uxh8cBm8cRZuLSGbMWYq/C+lKfkIZTpmClaVxbrnyhA8WAJeOwneuwTu2wxGIjYYl/FJ4yMOVInwx+1Asl7VY80vYfb9Jdi7OzgoG9yzCbx6HBw/CuzfG9wr7wWzwYmjwTvnJf6jMXWq3ucGkiWbzgvxhZXy+hocPAAcngs+vgYe3Q72kQ2GDQIrH4GnSkEALFktIHVgs0qOZRnLxjIB1NJQdjHPyXQS6sHoQ7CFB2b3MtDDWw1o3XLw3H7wxS21J7ApFrCsLRBAqTOXDNklUW7elJ0GCwvAnL4ieyN45Ri4az04YxJ4ejfYtg04ZKAlzA/VKZmqngIoReskxyIGKDIuHjK/nt0E504FNxaCx3fKfQW4SZ7nyNyiWTK3wyS7bDt1LRSwS8rDZIWVypQxYId24NJ8kzkhT5Ixx55zB9hGasGyhWCTFLt6b4CeAErFh5KdH9XgysXgyCEmVT88fwAsWmpWlJ0BW7U0cAf2PigfBYx306Ss+jcpwrJZgjt3Aju2B7esNpl9e1g5lW4At6/NkBsmpSZfALXRAw9DL5z0uLFas8T8mj8dnDkZnDfNqoCfM73zrGwargSFrY0etZ3SQSafX60uF8zUOrO203VVYHFZxq6qnxR2UwCop0ZFSN+YOj+FZe1TsHUrKxll1vQ2LBWNjzjvHWDj9fC0Cc5APTVc1RtTV0K14N0LloChObZBCOaAtEM0rr44OBwS4fGVDEBLzQsZiXIJrvV44WDE9XH+DLDxlbB/p8nzUnFpMP/vAzYA1VNDGt1PAafND+fMs+yUzMzv8d9fgD5Lo/vSm9pO2gEuk1Jnfs0Cl01NQCgzE+M32GtqShX2Xm8AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Linnaeus University logo",
                "title": "Linnaeus University logo",
                "src": "/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png",
                "srcSet": ["/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png 216w"],
                "sizes": "(max-width: 216px) 100vw, 216px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </ExperienceHeader>
      <ul>
        <li parentName="ul">{`Help students with Git, Node, and Javascript`}</li>
        <li parentName="ul">{`Teach programming concepts`}</li>
        <li parentName="ul">{`Mentor on how to work using an agile work process `}</li>
      </ul>
      <ExperienceHeader title="UX/UI Designer" period="Silverspin AB • Jan 2013 - present" mdxType="ExperienceHeader">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "216px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/9bb7d4b4d5d42efaf6ce9b72cf8d0151/7d4ec/silverspin.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": ["noopener"]
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAENUlEQVQ4y3VUe0xTdxS+yZb949/7ZzFTgjiCMEEoTIaCCy6TqVugMJg6Ig8hsDloL5dKKRRawAjyKAtzbGyiQmJgzk6q073qAGVxwHQg6sKjD0B5tTwWNin9dnrLvcLCTnKS23t/v6/fd853DoN1wul08umKZcey8BbfG3qgzWqE/N3TYKU1KM+/iN+6Hon3lpedYP4P0H3ADTYzOQtlSj1yvFi0vKJAu58KHZRNW3OR4cOhSt1Kf+zgz64LuBr078V/II/Xoc6TxUSwFrPBJbBTmoI1MAUWY1yiQYmHnGe7LqAg17Ei9ewn16HxkMEWUoJxAujYXoBcYnpoiwwZrxchz1+JG6+qwHpz6Pi57xmgwGh1/RaJnexABbp8VZiWaGHcrkLKriI01H4HnaoFaW+dxJVLv4KLqYZ8czZKM78CIwCsToGdaWQCWUEqjOwowhOSpvTJxVX9HVFNrfIirpy9CbttHodeK0DizkI3oMDMIXbUHUPDTyAjSaMk1bqjGPIAJczmSfG7nsCquGb+ua6gFe94s27JQjdd4aBujZunMDVuQ6exH3L/PIwSmCtZArxF7+zU9d87HyJtbym62wf4e82660h5QwtG8NnTp0toqrmG3PhasLE14OJ0SD9YwYOMkmTrCmAGedB9RgdjW7dIpDDpM5w7bXAznJ6wQy6txsnjjbjfPYQ52wJ/qL/PTAyVPDsBcOC+lTc56RLBjNfuIv3NMszbF4ghyeXe0/Hs/hsjVC9BstVfDW6nBg8NRsxmZmAsNgGjCUcwuS8KbZ670dcz7Pbht403eYlCPOgdRnn2eZRlfAku8VPIApQrNVQjW6LGqahcPJbGEWA8HicmYXijByZCw8QxZbKoJi7trhjst/CFbq3/EXdvPcLX53+hLq80JUANNqQYelU9JqJjYI6IhCV0N4ZefAnmXXvcBSCnMFI/BRrK9GLrL1Q/kz46NiMytPoXggsrwUDTZdhipbD4BWDkhQ0YZBiM7Ynkq8oDHg5RITmyhAdoqrqKxoo2EfA2WSJnG0kM0sBCgHIC7GvSw7LvAP6Uvo/B/dG49/wGDIWGi15mipLrkbbpY2ipw500i8f3l8NwoR3tP9zjbaP3U2KKpqSHxk9xsBKdP/UiLigZUkkq4oKPIcrnCBRJZeK4Mm2tXcj3ykGzbx6yIrRIDNfgbc9sJG9l8Y1fPqw0JbO0GGpoo3xRYUBvvwnPbTyGyIQqpHLnsClYgfDoU7yVXJCMk2xz4ugZNHhxsNDlOyStW1IMU1AxZkiqa8vovU8gNULD78WFvxYRc7gSm30/QmCYAi9v+xBnGm6sXbC2qTmwH9ShYAuL27SKTNSEocAidNGqKqc9mL63DA/+MK3xqLG9H6WVlzE9PbdmSzHCHDuWltBCNsmkuiWRVVIo0yNL8XmVAfaZedFn6y2R1b//BUggyy9AQnYuAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Silverspin logo",
                "title": "Silverspin logo",
                "src": "/static/9bb7d4b4d5d42efaf6ce9b72cf8d0151/7d4ec/silverspin.png",
                "srcSet": ["/static/9bb7d4b4d5d42efaf6ce9b72cf8d0151/7d4ec/silverspin.png 216w"],
                "sizes": "(max-width: 216px) 100vw, 216px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </ExperienceHeader>
      <ul>
        <li parentName="ul">{`Design casino websites`}</li>
        <li parentName="ul">{`Involved in significant redesigns for both desktop and mobile`}</li>
        <li parentName="ul">{`Create Whitelabel templates`}</li>
        <li parentName="ul">{`Create promotion material, icons, banners and much more `}</li>
      </ul>
      <ExperienceHeader title="Teacher" period="Teknikhögskolan • Sep 2012 - Dec 2012" mdxType="ExperienceHeader">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "216px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f949a6bae2909d1ed830ceee8db31ffd/7d4ec/th.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": ["noopener"]
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaUlEQVQ4y8VUXUgUURS+5UP10A8EQuRb9NQPpL1EIInmGhTYtrbuWqD9bCUR1BZFD/1R2w8sWEIR4UsPG76U9RAJokusVruBmqnrZhEUmtbm/rm7szOzX/dO3mFm3NyCogOHO3fume985zvnDsFfNvLPALPZrLpqn+UZ177LC6gFkWQZGUmETFej5QPTATKTDR+IkoRvsSjC8ZiSiLOdK4FaMg981heA66EHHybGEUtOY+URG9Y4D2IyMqV+xNlrK5vFUJoJMrnOgmxajXsdTxGZToBYyrH8gAWRRBwTFJQlyVWNDlCUJWXj8XWC7CrDgj3bUWDfhtLzJ7C4vhqFDiuqWKJaE4oa6/AyNKzqbWwW4QfMvIP9WNqwE6SmHGtPOnC0pRnL9plBzFuw6lg9Ld8OUlkC+62rSrwgiipTHSBvALO65usgW4vxyO9TggpsVVi0dweSQvpnBRUbYHZf/GX3Z2lY03QZxLQRD7q7IGQymE8BCx27IVI2bf5umqwE1qYruN3+BLabLoxPhXWaagA5w2sKQ6abxX0JxFqJhVTTdEZAa48XpGw9ik83Yh7VkzXvxuNWtUKGo5bMGQZGR7Du1CEsaajG/jtulF5wKs7OO9/2YsXhWjjv38UZTws2nzuO0NgndZRUQK5BirJgQofjUXyJfEeUjsjXaETxeCql6Miek4JAGWeQoqvx2uoAJ2lwW6AHXbTbfR/f40VoCL7gIPyjQXiH3qC9/zU6Bnrx6l0Qz4cHlP3I2GfdkBNjBnaP+XzxlXkinaIsk0ppTC/uORn+7sWfy7LGLmtp/6nnHOy8DP7XH/sHxB+qxYP+eQoAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Teknikhögskolan logo",
                "title": "Teknikhögskolan logo",
                "src": "/static/f949a6bae2909d1ed830ceee8db31ffd/7d4ec/th.png",
                "srcSet": ["/static/f949a6bae2909d1ed830ceee8db31ffd/7d4ec/th.png 216w"],
                "sizes": "(max-width: 216px) 100vw, 216px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </ExperienceHeader>
      <ul>
        <li parentName="ul">{`Teach ActionScript 3.0 and the foundation of OOP`}</li>
        <li parentName="ul">{`Create lecture material and assignments`}</li>
        <li parentName="ul">{`Assess and grade student assignments`}</li>
      </ul>
      <hr></hr>
      <h2>{`Education`}</h2>
      <ExperienceHeader title="Bachelor degree in CS" period="Linnaeus University • Aug 2019 - June 2020" mdxType="ExperienceHeader">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "216px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": ["noopener"]
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADVklEQVQ4y21VaYhNYRh+7hxL/ESUyM5gMoQwkl3JkiwZ1KSIMiEjDMpknWxl3yJ+oCxFU5TlhyUlP5AfJObOHXcYZsxkmUEz957H+37vOfdey6mvc873vef5nud53/c7oO/TXXr3m5m6fj6gX1dEVufRj3eV0YX8MELmVpI/7qTj9JsMDKTBEjb56wn96nFkBeiXy3IUTJbbSLyV8Qa2puA/7wdAyRQoQjB99b+dcMH6sV8RYTLqMRHNEpYR8qOMGnmu8dyaHw2Av+z5AxShTAcmQS4w7pHv5P5BRgN4ZBu4uxh8cBm8cRZuLSGbMWYq/C+lKfkIZTpmClaVxbrnyhA8WAJeOwneuwTu2wxGIjYYl/FJ4yMOVInwx+1Asl7VY80vYfb9Jdi7OzgoG9yzCbx6HBw/CuzfG9wr7wWzwYmjwTvnJf6jMXWq3ucGkiWbzgvxhZXy+hocPAAcngs+vgYe3Q72kQ2GDQIrH4GnSkEALFktIHVgs0qOZRnLxjIB1NJQdjHPyXQS6sHoQ7CFB2b3MtDDWw1o3XLw3H7wxS21J7ApFrCsLRBAqTOXDNklUW7elJ0GCwvAnL4ieyN45Ri4az04YxJ4ejfYtg04ZKAlzA/VKZmqngIoReskxyIGKDIuHjK/nt0E504FNxaCx3fKfQW4SZ7nyNyiWTK3wyS7bDt1LRSwS8rDZIWVypQxYId24NJ8kzkhT5Ixx55zB9hGasGyhWCTFLt6b4CeAErFh5KdH9XgysXgyCEmVT88fwAsWmpWlJ0BW7U0cAf2PigfBYx306Ss+jcpwrJZgjt3Aju2B7esNpl9e1g5lW4At6/NkBsmpSZfALXRAw9DL5z0uLFas8T8mj8dnDkZnDfNqoCfM73zrGwargSFrY0etZ3SQSafX60uF8zUOrO203VVYHFZxq6qnxR2UwCop0ZFSN+YOj+FZe1TsHUrKxll1vQ2LBWNjzjvHWDj9fC0Cc5APTVc1RtTV0K14N0LloChObZBCOaAtEM0rr44OBwS4fGVDEBLzQsZiXIJrvV44WDE9XH+DLDxlbB/p8nzUnFpMP/vAzYA1VNDGt1PAafND+fMs+yUzMzv8d9fgD5Lo/vSm9pO2gEuk1Jnfs0Cl01NQCgzE+M32GtqShX2Xm8AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Linnaeus University logo",
                "title": "Linnaeus University logo",
                "src": "/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png",
                "srcSet": ["/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png 216w"],
                "sizes": "(max-width: 216px) 100vw, 216px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </ExperienceHeader>
      <p>{`While I'm working at the university, I also study 50% to get a Bachelor's degree in computer science.`}</p>
      <h4>{`Courses`}</h4>
      <ul>
        <li parentName="ul">{`Foundations of Software Technology, 7.5 credits (Algorithms & Data Structures)`}</li>
        <li parentName="ul">{`Web intelligence, 7.5 credits (`}<a parentName="li" {...{
            "href": "https://github.com/AntonStrand/2DV515-A4-Machine-Learning"
          }}>{`Machine Learning`}</a>{`, `}<a parentName="li" {...{
            "href": "https://github.com/AntonStrand/2DV515-A3-search-engine"
          }}>{`Search Engine`}</a>{`, `}<a parentName="li" {...{
            "href": "https://github.com/AntonStrand/2DV515-A2-Clustering"
          }}>{`Clustering`}</a>{`, `}<a parentName="li" {...{
            "href": "https://github.com/AntonStrand/2DV515-a1-recommendation-system"
          }}>{`Recommendation System`}</a>{`)`}</li>
        <li parentName="ul">{`Degree Project at Bachelor Level, 15 credits (A paper on how to use CI pipelines to assess programming assignments)`}</li>
      </ul>
      <ExperienceHeader title="Web Development" period="Linnaeus University • Aug 2017 - June 2019" mdxType="ExperienceHeader">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "216px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": ["noopener"]
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADVklEQVQ4y21VaYhNYRh+7hxL/ESUyM5gMoQwkl3JkiwZ1KSIMiEjDMpknWxl3yJ+oCxFU5TlhyUlP5AfJObOHXcYZsxkmUEz957H+37vOfdey6mvc873vef5nud53/c7oO/TXXr3m5m6fj6gX1dEVufRj3eV0YX8MELmVpI/7qTj9JsMDKTBEjb56wn96nFkBeiXy3IUTJbbSLyV8Qa2puA/7wdAyRQoQjB99b+dcMH6sV8RYTLqMRHNEpYR8qOMGnmu8dyaHw2Av+z5AxShTAcmQS4w7pHv5P5BRgN4ZBu4uxh8cBm8cRZuLSGbMWYq/C+lKfkIZTpmClaVxbrnyhA8WAJeOwneuwTu2wxGIjYYl/FJ4yMOVInwx+1Asl7VY80vYfb9Jdi7OzgoG9yzCbx6HBw/CuzfG9wr7wWzwYmjwTvnJf6jMXWq3ucGkiWbzgvxhZXy+hocPAAcngs+vgYe3Q72kQ2GDQIrH4GnSkEALFktIHVgs0qOZRnLxjIB1NJQdjHPyXQS6sHoQ7CFB2b3MtDDWw1o3XLw3H7wxS21J7ApFrCsLRBAqTOXDNklUW7elJ0GCwvAnL4ieyN45Ri4az04YxJ4ejfYtg04ZKAlzA/VKZmqngIoReskxyIGKDIuHjK/nt0E504FNxaCx3fKfQW4SZ7nyNyiWTK3wyS7bDt1LRSwS8rDZIWVypQxYId24NJ8kzkhT5Ixx55zB9hGasGyhWCTFLt6b4CeAErFh5KdH9XgysXgyCEmVT88fwAsWmpWlJ0BW7U0cAf2PigfBYx306Ss+jcpwrJZgjt3Aju2B7esNpl9e1g5lW4At6/NkBsmpSZfALXRAw9DL5z0uLFas8T8mj8dnDkZnDfNqoCfM73zrGwargSFrY0etZ3SQSafX60uF8zUOrO203VVYHFZxq6qnxR2UwCop0ZFSN+YOj+FZe1TsHUrKxll1vQ2LBWNjzjvHWDj9fC0Cc5APTVc1RtTV0K14N0LloChObZBCOaAtEM0rr44OBwS4fGVDEBLzQsZiXIJrvV44WDE9XH+DLDxlbB/p8nzUnFpMP/vAzYA1VNDGt1PAafND+fMs+yUzMzv8d9fgD5Lo/vSm9pO2gEuk1Jnfs0Cl01NQCgzE+M32GtqShX2Xm8AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Linnaeus University logo",
                "title": "Linnaeus University logo",
                "src": "/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png",
                "srcSet": ["/static/09ce4e71d7d663a5af096b0ff87ee974/7d4ec/lnu.png 216w"],
                "sizes": "(max-width: 216px) 100vw, 216px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </ExperienceHeader>
      <p>{`Higher Education Certificate in web development.`}</p>
      <h4>{`Courses year 1`}</h4>
      <ul>
        <li parentName="ul">{`Introduction to Programming, 7.5 credits`}</li>
        <li parentName="ul">{`Web Technology 1, 7.5 credits`}</li>
        <li parentName="ul">{`Client­-based Web Programming, 7.5 credits`}</li>
        <li parentName="ul">{`Graphical tools, 7.5 credits`}</li>
        <li parentName="ul">{`Software Technology, 7.5 credits`}</li>
        <li parentName="ul">{`Server­-based Web Programming, 7.5 credits`}</li>
        <li parentName="ul">{`Object-­Oriented Programming, 7.5 credits`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/antonstrand/tasktracker"
          }}>{`Individual Software Development Project`}</a>{`, 7.5 credits`}</li>
      </ul>
      <h4>{`Courses year 2`}</h4>
      <ul>
        <li parentName="ul">{`Introduction to software quality, 7.5 credits`}</li>
        <li parentName="ul">{`Object-Oriented Analysis and Design using UML, 7.5 credits`}</li>
        <li parentName="ul">{`Software Testing, 7.5 credits`}</li>
        <li parentName="ul">{`Database Theory, 7.5 credits`}</li>
        <li parentName="ul">{`The Web as an Application Platform, 7.5 credits`}</li>
        <li parentName="ul">{`Web Application Architectures and Frameworks, 7.5 credits`}</li>
        <li parentName="ul">{`Team­-based Software Development Project, 7.5 credits`}</li>
        <li parentName="ul">{`Computer Science, Independent Project (Higher Education
Certificate), 7.5 credits `}</li>
      </ul>
      <ExperienceHeader title="Advanced Flash Developer" period="Teknikhögskolan • Sep 2011 - June 2012" mdxType="ExperienceHeader">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "216px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f949a6bae2909d1ed830ceee8db31ffd/7d4ec/th.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": ["noopener"]
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaUlEQVQ4y8VUXUgUURS+5UP10A8EQuRb9NQPpL1EIInmGhTYtrbuWqD9bCUR1BZFD/1R2w8sWEIR4UsPG76U9RAJokusVruBmqnrZhEUmtbm/rm7szOzX/dO3mFm3NyCogOHO3fume985zvnDsFfNvLPALPZrLpqn+UZ177LC6gFkWQZGUmETFej5QPTATKTDR+IkoRvsSjC8ZiSiLOdK4FaMg981heA66EHHybGEUtOY+URG9Y4D2IyMqV+xNlrK5vFUJoJMrnOgmxajXsdTxGZToBYyrH8gAWRRBwTFJQlyVWNDlCUJWXj8XWC7CrDgj3bUWDfhtLzJ7C4vhqFDiuqWKJaE4oa6/AyNKzqbWwW4QfMvIP9WNqwE6SmHGtPOnC0pRnL9plBzFuw6lg9Ld8OUlkC+62rSrwgiipTHSBvALO65usgW4vxyO9TggpsVVi0dweSQvpnBRUbYHZf/GX3Z2lY03QZxLQRD7q7IGQymE8BCx27IVI2bf5umqwE1qYruN3+BLabLoxPhXWaagA5w2sKQ6abxX0JxFqJhVTTdEZAa48XpGw9ik83Yh7VkzXvxuNWtUKGo5bMGQZGR7Du1CEsaajG/jtulF5wKs7OO9/2YsXhWjjv38UZTws2nzuO0NgndZRUQK5BirJgQofjUXyJfEeUjsjXaETxeCql6Miek4JAGWeQoqvx2uoAJ2lwW6AHXbTbfR/f40VoCL7gIPyjQXiH3qC9/zU6Bnrx6l0Qz4cHlP3I2GfdkBNjBnaP+XzxlXkinaIsk0ppTC/uORn+7sWfy7LGLmtp/6nnHOy8DP7XH/sHxB+qxYP+eQoAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Teknikhögskolan logo",
                "title": "Teknikhögskolan logo",
                "src": "/static/f949a6bae2909d1ed830ceee8db31ffd/7d4ec/th.png",
                "srcSet": ["/static/f949a6bae2909d1ed830ceee8db31ffd/7d4ec/th.png 216w"],
                "sizes": "(max-width: 216px) 100vw, 216px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </ExperienceHeader>
      <ul>
        <li parentName="ul">{`Advanced Object-Oriented ActionScript 3.0`}</li>
      </ul>
      <br />
      <br />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      